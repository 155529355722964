import { useFormikContext } from 'formik'
import { ChangeEvent } from 'react'
import { ViewProps } from 'react-native'
import * as yup from 'yup'

import { AddressAttribute, OrderServiceType, PropertyType } from '../../../types/graphql'
import { QuestionGroup, fieldName, propertyTypeQuestionGroupAttributesMap } from './AddressAttributesInput'
import { ControlledSelect } from './Input/ControlledSelect'
import { InstructionsFormType } from './InstructionsForm'
import { PREFERRED_LOCATIONS } from './preferredLocations'

export interface PropertyTypeInputType {
  type: PropertyType
}

export const propertyTypes = [
  {
    label: 'House',
    value: PropertyType.House,
  },
  {
    label: 'Apt/Unit',
    value: PropertyType.Apartment,
  },
  {
    label: 'Business',
    value: PropertyType.Business,
  },
]

export const propertyTypeFieldSchema = yup
  .string()
  .test('is-property-type', 'Must be a valid property type.', value => {
    if (value && !propertyTypes.find(propertyType => propertyType.value === value)) {
      return false
    }

    return true
  })
  .nullable()

export interface PropertyTypesInputProps extends ViewProps {
  serviceType: OrderServiceType
}

export const PropertyTypesInput = ({ serviceType, ...viewProps }: PropertyTypesInputProps) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<InstructionsFormType>()

  // When property type change we need to map conditional selections and values based on the applicability of the property type.
  const handlePropertyTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value as PropertyType
    const oldType = values.type

    // Set the preferred location when property type changes
    const locationFieldName = serviceType === OrderServiceType.Pickup ? 'preferredPickupLocation' : 'preferredLocation'
    const locationFieldValue = values[locationFieldName]
    const newLocationFieldValue =
      locationFieldValue === PREFERRED_LOCATIONS.NotSet ? PREFERRED_LOCATIONS.NotSet : PREFERRED_LOCATIONS.NoPreference
    setFieldValue(locationFieldName, newLocationFieldValue)

    // Mapping attributes when type change based on the available attribute question applicable to that question
    const mappedAttributes: AddressAttribute[] = Object.entries(
      propertyTypeQuestionGroupAttributesMap[oldType as PropertyType]
    )?.reduce((mapped, [key, attributes]) => {
      if (values.attributes?.filter((attr: AddressAttribute) => attributes?.includes(attr)).length > 0) {
        return [
          ...mapped,
          ...(propertyTypeQuestionGroupAttributesMap[newType as PropertyType][key as QuestionGroup] ?? []),
        ]
      }
      return mapped
    }, [] as AddressAttribute[])
    setFieldValue(fieldName, mappedAttributes)
    setFieldValue('type', newType)
  }

  return (
    <ControlledSelect name={'type'} label={'Property Type'} onChange={handlePropertyTypeChange} disabled={isSubmitting}>
      {propertyTypes.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </ControlledSelect>
  )
}
