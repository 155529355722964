import { ConfirmationResult, UserCredential } from 'firebase/auth'
import { Formik, FormikHelpers } from 'formik'
import { Modal, VStack, useToast } from 'native-base'
import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'

import client from '../../apolloClient'
import { AGREE_TO_TERMS_AND_CONDITIONS } from '../../graphql/agreeToTermsAndConditions'
import { UPDATE_CLIENT_ADDRESS_INSTRUCTIONS_FOR_ORDER } from '../../graphql/updateClientAddressInstructionsForOrder'
import { AddressAttribute, Order, PropertyType } from '../../types/graphql'
import { trackEvent } from '../../utils/analytics-v2'
import { signInWithPhoneNumber } from '../../utils/firebase'
import { ToastAlert } from './Common/ToastAlert'
import { ErrorModalContent } from './Error/ErrorModalContent'
import { PhoneNumberMismatchModalContent } from './Error/PhoneNumberMismatchModalContent'
import { InstructionsFormType, instructionsFormSchema } from './Form/InstructionsForm'
import { InstructionsFormModalContent } from './Form/InstructionsFormModalContent'
import { PREFERRED_LOCATIONS } from './Form/preferredLocations'
import { PhoneFormType, phoneFormSchema } from './Phone/PhoneInput'
import { PhoneModalContent } from './Phone/PhoneModalContent'
import { SubmittingModalContent } from './Submitting/SubmittingModalContent'
import { VerificationFormType, verificationFormSchema } from './Verification/VerificationInput'
import { VerificationModalContent } from './Verification/VerificationModalContent'

const normalizePhone = (phoneNumberString: string) => {
  // TO DO: Add normalization function based on region
  return '+' + phoneNumberString.replace(/[^\d]/g, '')
}

enum Steps {
  Instructions = 0,
  Phone = 1,
  Verification = 2,
  Submitting = 3,
  PhoneNumberNotMatched = 4,
  Error = 5,
}

const steps = [
  { name: 'Instructions', validationSchema: instructionsFormSchema, validateOnChange: true },
  { name: 'Phone', validationSchema: phoneFormSchema, validateOnChange: false },
  { name: 'Verification', validationSchema: verificationFormSchema, validateOnChange: false },
  { name: 'Submitting', validationSchema: null, validateOnChange: false },
  { name: 'PhoneNumberNotMatched', validationSchema: null, validateOnChange: false },
  { name: 'Error', validationSchema: null, validateOnChange: false },
]

export type StepContentProps = {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  order: Order
  closeModal: () => void
  resendAuth: () => void
  resendLoading: boolean
  disableSubmit: boolean
  disableResend: boolean
}

/**
 * Render the main content of the modal based on the current step.
 * @param step current step of the instructions update process.
 * @returns Modal content based on the current step.
 */
const StepContent = ({
  step,
  setStep,
  order,
  closeModal,
  resendAuth,
  resendLoading,
  disableResend,
  disableSubmit,
}: StepContentProps) => {
  switch (step) {
    case Steps.Instructions:
      return <InstructionsFormModalContent order={order} closeModal={closeModal} />
    case Steps.Phone:
      return <PhoneModalContent closeModal={closeModal} />
    case Steps.Verification:
      return (
        <VerificationModalContent
          closeModal={closeModal}
          resendAuth={resendAuth}
          resendLoading={resendLoading}
          disableSubmit={disableSubmit}
          disableResend={disableResend}
        />
      )
    case Steps.Submitting:
      return <SubmittingModalContent />
    case Steps.PhoneNumberNotMatched:
      return <PhoneNumberMismatchModalContent closeModal={closeModal} onSubmit={() => setStep(Steps.Phone)} />
    case Steps.Error:
      return <ErrorModalContent closeModal={closeModal} onSubmit={() => setStep(Steps.Phone)} />
    default:
      return <div>Not Found</div>
  }
}

export type ModalFormType = InstructionsFormType & PhoneFormType & VerificationFormType

export type InstructionsModalProps = {
  order: Order
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function InstructionsModal({
  order,
  isOpen,
  setIsOpen,
  activeStep,
  setActiveStep,
}: InstructionsModalProps) {
  const toast = useToast()

  // Setup the phone validation
  const [recaptchaWrapperRef, setRecaptchaWrapperRef] = useState<HTMLDivElement | null>(null)
  const [resendLoading, setResendLoading] = useState(false)
  const [normalizedPhoneNumber, setNormalizedPhoneNumber] = useState<string | null>(null)
  const [submitVerifyPhoneLoading, setSubmitVerifyPhoneLoading] = useState(false)
  const [confirmation, setConfirmation] = useState<ConfirmationResult>()
  const [verifyAttemptCount, setVerifyAttemptCount] = useState(0)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [disableResend, setDisableResend] = useState(false)

  const [agreedToTermsAndConditions] = useMutation(AGREE_TO_TERMS_AND_CONDITIONS)
  const [updateInstructions] = useMutation(UPDATE_CLIENT_ADDRESS_INSTRUCTIONS_FOR_ORDER, {
    onCompleted: () => {
      // Submission successful, which means we have verified the phone number and that the phone number matches the order
      // Submit the terms and conditions agreement that was required to submit the instructions
      // TODO: should we do this earlier in the process?
      //   My thinking was to avoid a race condition with updating the instructions,
      //   and also to avoid adding more latency to the instructions update process
      agreedToTermsAndConditions()
      // Refetch all queries to update the UI
      client.refetchQueries({ include: 'all' })
      // Close the modal
      setIsOpen(false)
      // Show a success toast
      trackEvent('add_instructions_success', {})
      toast.show({
        placement: 'top',
        render: ({ id }) => (
          <ToastAlert
            id={id}
            title={'Instructions updated!'}
            description={'Added your instructions to this delivery'}
            status={'success'}
            variant={'subtle'}
            isClosable={false}
            closeToast={toast.close}
          />
        ),
      })
    },
    onError: error => {
      if (error.message === 'Authorized consumer does not match order consumer') {
        // Submission failed due to phone number not matching
        trackEvent('add_instructions_error', { error_reason: 'mismatched_phone' })
        setActiveStep(Steps.PhoneNumberNotMatched)
      } else {
        // Submission failed due to an unknown error
        trackEvent('add_instructions_error', { error_reason: 'unknown' })
        setActiveStep(Steps.Error)
      }
    },
  })

  /**
   * Handle the submission of the phone number form. Normalizes the phone number and sends it to Firebase for verification.
   */
  const onPhoneFormSubmit = useCallback(
    async ({ phoneNumber, setError }: { phoneNumber: string; setError: (msg: string) => void }) => {
      try {
        trackEvent('get_authentication_code', { auth_flow: 'instructions' })
        const normalizedPhone = normalizePhone(phoneNumber)

        if (recaptchaWrapperRef) {
          // Save the normalized phone number incase we need to resend the verification code
          setNormalizedPhoneNumber(normalizedPhone)
          // Firebase auth requires phone number in format +1 5557770160
          const firebaseConfirmation = await signInWithPhoneNumber(normalizedPhone, recaptchaWrapperRef)
          if (firebaseConfirmation) {
            trackEvent('get_authentication_code_success', { auth_flow: 'instructions' })
            setConfirmation(firebaseConfirmation)
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (e.message.includes('reCAPTCHA')) {
            trackEvent('get_authentication_code_error', {
              auth_flow: 'instructions',
              error_reason: 'verification_failed',
            })
            setError('Phone verification failed. Please reload the page and try again.')
          } else {
            trackEvent('get_authentication_code_error', {
              auth_flow: 'instructions',
              error_reason: 'unknown',
            })
            setError('An error occurred. Please try again.')
          }
        }
        return false
      }
      return true
    },
    [recaptchaWrapperRef]
  )

  /**
   * Handle the submission of the verification code form. Sends the code to Firebase for verification.
   * If the code is correct, the phone number is verified and the page will be reloaded with the authenticated user.
   * If the code is incorrect, the user will be prompted to try again.
   */
  const onVerifyPhoneSubmit = useCallback(
    async ({ values, setError }: { values: Partial<ModalFormType>; setError: (msg: string) => void }) => {
      const {
        confirmationCode,
        type,
        attributes,
        instructions,
        preferredLocation,
        pickupInstructions,
        preferredPickupLocation,
        securityCode,
      } = values
      if (confirmation) {
        trackEvent('submit_verification_code', { auth_flow: 'instructions' })
        setSubmitVerifyPhoneLoading(true)

        let confirmRes: UserCredential
        try {
          confirmRes = await confirmation.confirm(confirmationCode!)
          if (confirmRes) {
            trackEvent('submit_verification_code_success', { auth_flow: 'instructions' })
            updateInstructions({
              errorPolicy: 'none',
              notifyOnNetworkStatusChange: true,
              variables: {
                orderId: order.orderId,
                updateInstructionsInput: {
                  attributes,
                  instructions: instructions == null ? undefined : instructions,
                  pickupInstructions: instructions == null ? undefined : pickupInstructions,
                  preferredLocation: preferredLocation === PREFERRED_LOCATIONS.NotSet ? undefined : preferredLocation,
                  preferredPickupLocation,
                  securityCode,
                  type,
                },
              },
            })
          }
        } catch (e: unknown) {
          setSubmitVerifyPhoneLoading(false)
          setVerifyAttemptCount(verifyAttemptCount + 1)
          if (verifyAttemptCount >= 3) {
            setDisableSubmit(true)
            setError('Too many attempts. Please try again later.')
            trackEvent('submit_verification_code_error', {
              auth_flow: 'instructions',
              error_reason: 'too_many_attempts',
            })
          } else {
            if (e instanceof Error) {
              if (e?.message?.includes('auth/invalid-verification-code')) {
                trackEvent('submit_verification_code_error', {
                  auth_flow: 'instructions',
                  error_reason: 'code_mismatch',
                })
                setError('Verification code does not match.')
              } else if (e?.message?.includes('auth/code-expired')) {
                trackEvent('submit_verification_code_error', {
                  auth_flow: 'instructions',
                  error_reason: 'code_expired',
                })
                setError('Verification code has expired.')
              } else {
                setError('An error occurred. Please try again.')
              }
            }
          }

          return false
        }
      }
      return true
    },
    [confirmation, verifyAttemptCount, setSubmitVerifyPhoneLoading, order, updateInstructions]
  )

  /**
   * Resend the authentication code to the user's phone number.
   * Only allows resending once per session (due to a limitation with reloading the ReCAPTCHA widget).
   */
  const resendAuth = useCallback(async () => {
    if (!submitVerifyPhoneLoading && !disableResend) {
      setResendLoading(true)
      if (normalizedPhoneNumber && recaptchaWrapperRef) {
        try {
          trackEvent('get_authentication_code', { auth_flow: 'instructions' })
          const firebaseConfirmation = await signInWithPhoneNumber(normalizedPhoneNumber, recaptchaWrapperRef)

          if (firebaseConfirmation) {
            trackEvent('get_authentication_code_success', { auth_flow: 'instructions' })
            setConfirmation(firebaseConfirmation)
          }
        } catch (e) {
          trackEvent('get_authentication_code_error', { auth_flow: 'instructions', error_reason: 'unknown' })
          // TODO: Do we want to display an error message?
        } finally {
          setDisableResend(true)
        }
      }
      setResendLoading(false)
    }
  }, [normalizedPhoneNumber, submitVerifyPhoneLoading, disableResend, recaptchaWrapperRef])

  // Setup the initial values for the form
  const { validationSchema, validateOnChange } = steps[activeStep]

  const filteredAttributes: AddressAttribute[] = order.address.attributes?.filter(Boolean).map(attr => attr!) ?? []
  const initialValues: Partial<ModalFormType> = {
    type: order.address.type ?? PropertyType.House,
    attributes: filteredAttributes,
    securityCode: order.address.securityCode ?? '',
    phoneNumber: '',
    acceptedTerms: false,
    confirmationCode: '',
  }
  // Since these two fields are optional and hidden by default, we want to have the concept of "NotSet".
  // If the user has never opened the other instructions section, they have not made any selections for these fields
  // so we should not update the values in the database.
  if (order.serviceType === 'pickup') {
    initialValues.preferredPickupLocation = order.address.preferredPickupLocation ?? PREFERRED_LOCATIONS.NotSet
    initialValues.pickupInstructions = order.address.pickupInstructions ?? null
  } else {
    initialValues.preferredLocation = order.address.preferredLocation ?? PREFERRED_LOCATIONS.NotSet
    initialValues.instructions = order.address.instructions ?? null
  }

  /**
   * Close the instructions modal.
   */
  const _closeModal = () => {
    setIsOpen(false)
  }

  /**
   * Handle the submission of the form based on the current step.
   * Will do step-specific actions, then progress the submission flow forward if there are no errors.
   * @param values Form values.
   * @param formikHelpers Formik helpers functions.
   */
  const _handleSubmit = async (
    values: Partial<ModalFormType>,
    formikHelpers: FormikHelpers<Partial<ModalFormType>>
  ) => {
    let moveToNextStep = true
    switch (activeStep) {
      case Steps.Phone:
        moveToNextStep = await onPhoneFormSubmit({
          phoneNumber: values.phoneNumber!,
          setError: msg => formikHelpers.setErrors({ phoneNumber: msg }),
        })
        break
      case Steps.Verification:
        moveToNextStep = await onVerifyPhoneSubmit({
          values,
          setError: msg => formikHelpers.setErrors({ confirmationCode: msg }),
        })
        break
      case Steps.Instructions:
        trackEvent('add_instructions', {})
        break
      default:
        break
    }

    if (moveToNextStep) {
      setActiveStep(step => step + 1)
      formikHelpers.setTouched({})
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <VStack space={3} alignItems={'center'}>
      <Modal isOpen={isOpen} onClose={_closeModal} size={'xl'}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          validateOnMount
          validateOnChange={validateOnChange}
          onSubmit={_handleSubmit}
        >
          <StepContent
            step={activeStep}
            setStep={setActiveStep}
            order={order}
            closeModal={_closeModal}
            resendAuth={resendAuth}
            resendLoading={resendLoading}
            disableSubmit={disableSubmit}
            disableResend={disableResend}
          />
        </Formik>
      </Modal>
      <div ref={ref => setRecaptchaWrapperRef(ref)}>
        <div id="recaptcha-container"></div>
      </div>
    </VStack>
  )
}
