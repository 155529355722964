const events = {
  CREATED: 'created', // PICKUPS AND DELIVERIES
  CANCELLED: 'cancelled',
  PICKED_UP_FROM_CLIENT: 'pickedUpFromClient', // DELIVERIES
  DROPPED_OFF_AT_VEHO: 'droppedOffAtVeho',
  PICKED_UP_FROM_VEHO: 'pickedUpFromVeho',
  DELIVERED: 'delivered',
  DISCARDED: 'discarded',
  RETURNED: 'returned',
  RETURNED_TO_CLIENT: 'returnedToClient',
  RETURNED_TO_VEHO: 'returnedToVeho',
  PENDING: 'pending',
  MISDELIVERED: 'misdelivered',
  NOT_FOUND: 'notFound',
  NOT_RECEIVED_FROM_CLIENT: 'notReceivedFromClient',
  OUT_FOR_PICKUP: 'outForPickup', // PICKUPS
  PICKED_UP_FROM_CONSUMER: 'pickedUpFromConsumer',
  PICKUP_SKIPPED: 'pickupSkipped',
  PICKUP_FAILED: 'pickupFailed',
  PICKUP_DROPPED_OFF_AT_VEHO: 'pickupDroppedOffAtVeho',
  PACKAGE_ARRIVED_AT_FACILITY: 'PackageArrivedAtFacility',
  PACKAGE_DEPARTED_FROM_FACILITY: 'PackageDepartedFromFacility',
  PACKAGE_HAD_DELIVERY_ISSUE: 'PackageHadDeliveryIssue',
  PACKAGE_DELAYED: 'PackageDelayed',
}

export const errorEvents = [
  events.CANCELLED,
  events.DISCARDED,
  events.RETURNED,
  events.RETURNED_TO_CLIENT,
  events.RETURNED_TO_VEHO,
  events.MISDELIVERED,
  events.NOT_RECEIVED_FROM_CLIENT,
  events.PICKUP_SKIPPED,
  events.PICKUP_FAILED,
  events.PACKAGE_HAD_DELIVERY_ISSUE,
  events.PACKAGE_DELAYED,
]

export enum PackageDelayedIssue {
  accessIssueFrontGate = 'AccessIssues - FrontGate',
  accessIssueBuildingOrDoorCode = 'AccessIssues - BuildingDoorCode',
  accessIssueSchoolCampus = 'AccessIssues - SchoolCampus',
  wrongLocation = 'WrongLocation',
  closedLocationStoreFront = 'ClosedLocation - BusinessStoreFront',
  closedLocationOffice = 'ClosedLocation - BusinessOffice',
  closedLocationLeasingOffice = 'ClosedLocation - LeasingOffice',
  closedLocationMailroom = 'ClosedLocation - Mailroom',
  customerRefused = 'CustomerRefused',
  damagedPackage = 'DamagedPackage',
  unsafeConditionsPetOnSite = 'UnsafeConditions - PetOnSite',
  unsafeConditionsWeatherRelated = 'UnsafeConditions - WeatherRelated',
  unsafeConditionsDarkUnlitArea = 'UnsafeConditions - DarkOrUnlitArea',
  other = 'Other',
  packageDelayed = 'PackageDelayed',
}

export default events
