import { Box, Image, Pressable } from 'native-base'
import { isMobile } from 'react-device-detect'

import SupportButton from '../atoms/SupportButton'
import { trackEvent } from '../utils/analytics-v2'

export default function NavBar() {
  const handleLinkClick = (href: string) => {
    trackEvent('navigate_to_main_site', { element: 'logo' })
    window.open(href, '_self')
  }
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
      px={3}
    >
      <Box display="flex" justifyContent="center">
        <Pressable
          id="vehoMarketing"
          onPress={() => {
            handleLinkClick('https://www.shipveho.com/')
          }}
        >
          <Image source={{ uri: 'veho-logo.svg' }} resizeMode="contain" size="md" alt="Logo" />
        </Pressable>
      </Box>
      {isMobile && (
        <Box display="flex" justifyContent="center" mr={-4}>
          <SupportButton />
        </Box>
      )}
    </Box>
  )
}
