import { ArrowBackIcon, Box, Button, Link, Text } from 'native-base'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import { AppContext } from '../context/AppContextProvider'
import { GET_ORDER_BY_TRACKING_ID } from '../graphql/getOrderByTrackingId'
import { Order } from '../types/graphql'
import { trackEvent } from '../utils/analytics-v2'
import FAQ from './FAQ'
import Loader from './Loader'
import OrderInfo from './OrderInfo'
import PackageInfo from './PackageInfo'

const SearchResultsPage = () => {
  const { trackingId } = useParams()
  const { order, setOrder } = useContext(AppContext)

  const { loading, error, data } = useQuery<{
    order: Order
  }>(GET_ORDER_BY_TRACKING_ID, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { trackingId },
  })

  const navigate = useNavigate()

  // GestureResponderEvent not exported by NativeBase
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBackToSearch = (e: any) => {
    e.preventDefault()
    trackEvent('return_to_tracking_page', {})
    navigate('/')
  }

  useEffect(() => {
    try {
      if (data) {
        setOrder(data?.order)
      }
    } catch (e) {
      //update to error formats
      console.log('error', e)
    }
  }, [data, setOrder])

  return (
    <>
      {loading && <Loader />}
      {error && (
        <Box style={{ margin: 'auto' }} display="flex" justifyContent="center" alignItems="flex-start">
          <Text style={{ color: '#7B0000', maxWidth: 400, margin: 20 }}>
            Looks like there was an issue fetching the tracking info. Please retry.
          </Text>
        </Box>
      )}
      {!loading && !error && data?.order === null && (
        <Box style={{ margin: 'auto' }} display="flex" justifyContent="center" alignItems="flex-start">
          <Text style={{ color: '#7B0000', maxWidth: 400, margin: 20 }}>
            We can't find a package associated with that number. Please double check your tracking ID or Barcode. If you
            need help, text <Link href="sms:68953">68953</Link> to contact support.
          </Text>
        </Box>
      )}
      {!loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="stretch"
          alignContent="stretch"
          flexDirection="row"
          px={3}
          py={4}
          width="100%"
        >
          <div id="searchResults">
            <Box>
              <Box>
                <Button
                  onPress={onBackToSearch}
                  size="sm"
                  width="212px"
                  height="32px"
                  borderRadius={100}
                  bgColor="#ECECE5"
                  leftIcon={<ArrowBackIcon style={{ fontSize: 14, color: '#293042' }} />}
                >
                  <Text color="#293042" fontWeight={600} fontSize={14}>
                    Track Another Package
                  </Text>
                </Button>
              </Box>
              {data?.order !== null && order && (
                <Box>
                  <OrderInfo />
                  <PackageInfo />
                </Box>
              )}
            </Box>
          </div>
        </Box>
      )}
      <FAQ />
    </>
  )
}

export default SearchResultsPage
