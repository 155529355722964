import { useFormikContext } from 'formik'
import { Text, VStack } from 'native-base'
import React, { useMemo } from 'react'
import { LayoutAnimation, ViewProps } from 'react-native'

import InfoCircleSVG from '../../../assets/InfoCircleSVG'
import { AddressAttribute, PropertyType } from '../../../types/graphql'
import { colors } from '../../../utils/theme/configureTheme'
import ControlledTextInput from './Input/ControlledTextInput'
import YesNoInput from './Input/YesNoInput'
import { PropertyTypeInputType } from './PropertyTypesInput'

export const fieldName = 'attributes'

export interface AddressAttributesInputType {
  attributes: AddressAttribute[]
}

export type AttributeQuestion = {
  question: string
  attributes: AddressAttribute[]
}

export enum QuestionGroup {
  UseCodeToAccess = 'useCodeToAccess',
  HasDog = 'hasDog',
}

export const propertyTypeQuestionGroupAttributesMap: Record<
  PropertyType,
  Partial<Record<QuestionGroup, AddressAttribute[]>>
> = {
  [PropertyType.House]: {
    [QuestionGroup.HasDog]: [AddressAttribute.HasDog],
    [QuestionGroup.UseCodeToAccess]: [AddressAttribute.GatedCommunity, AddressAttribute.UseCodeToAccess],
  },
  [PropertyType.Apartment]: {
    [QuestionGroup.UseCodeToAccess]: [AddressAttribute.UseCodeToAccess],
  },
  [PropertyType.Business]: {
    [QuestionGroup.UseCodeToAccess]: [AddressAttribute.UseCodeToAccess],
  },
  [PropertyType.Other]: {
    [QuestionGroup.UseCodeToAccess]: [AddressAttribute.UseCodeToAccess],
  },
}

const hasDogQuestion = 'Should we beware of a dog?'
export const addressAttributesQuestions = {
  [PropertyType.House]: {
    [QuestionGroup.UseCodeToAccess]: 'Are you in a gated community?',
    [QuestionGroup.HasDog]: hasDogQuestion,
  },
  [PropertyType.Apartment]: {
    [QuestionGroup.UseCodeToAccess]: 'Security Code or Callbox to access?',
    [QuestionGroup.HasDog]: hasDogQuestion,
  },
  [PropertyType.Business]: {
    [QuestionGroup.UseCodeToAccess]: 'Security Code or Callbox to access?',
    [QuestionGroup.HasDog]: hasDogQuestion,
  },
  [PropertyType.Other]: {
    [QuestionGroup.UseCodeToAccess]: 'Security Code or Callbox to access?',
    [QuestionGroup.HasDog]: hasDogQuestion,
  },
}

const SECURITY_CODE_INPUT_LABEL = {
  [PropertyType.House]: 'Security/Gate Code',
  [PropertyType.Apartment]: 'Access/Callbox Code',
  [PropertyType.Business]: 'Access/Callbox Code',
  [PropertyType.Other]: 'Access Code',
}

export const AddressAttributesInput = (prop: ViewProps) => {
  const { values, errors, isSubmitting, setFieldValue } = useFormikContext<
    AddressAttributesInputType & PropertyTypeInputType
  >()

  const showAccessCodeInput = useMemo(
    () => values.attributes?.find((attr: AddressAttribute | null) => attr === AddressAttribute.UseCodeToAccess),
    [values.attributes]
  )

  return (
    <VStack space={2} {...prop}>
      <VStack space={2}>
        {Object.entries(propertyTypeQuestionGroupAttributesMap[values.type]).map(([questionGroup, attributes]) => {
          const key = `${questionGroup}_attribute`
          const value = values.attributes?.filter(attr => attributes.includes(attr)).length === attributes.length
          return (
            <YesNoInput
              key={key}
              label={addressAttributesQuestions[values.type][questionGroup as QuestionGroup]}
              name={key}
              disabled={isSubmitting}
              handleChange={(newValue: boolean) => {
                if (newValue === value) {
                  return
                }
                LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
                const cleanAttr = values.attributes?.filter(attr => !attributes.includes(attr)) ?? []
                if (newValue) {
                  setFieldValue(fieldName, [...cleanAttr, ...attributes])
                } else {
                  setFieldValue(fieldName, cleanAttr)
                }
              }}
              value={value}
            />
          )
        })}
        {showAccessCodeInput && (
          <ControlledTextInput
            hint={'Include additional characters such as # or *'}
            leftHintIcon={<InfoCircleSVG />}
            placeholder={'e.g. 123456#'}
            label={SECURITY_CODE_INPUT_LABEL[values.type]}
            name="securityCode"
            optional={true}
            charLimit={20}
            charLimitCounter={false}
          />
        )}
      </VStack>
      {errors.attributes && (
        <Text
          style={{
            fontSize: 12,
            letterSpacing: 0,
            lineHeight: 15,
            fontWeight: '500',
            color: colors.error[300],
          }}
          testID={'attributes-error'}
        >
          {errors.attributes}
        </Text>
      )}
    </VStack>
  )
}
