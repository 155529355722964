import { Field } from 'formik'
import { FormControl } from 'native-base'
import { ChangeEvent } from 'react'

import CaretDownSVG from '../../../../assets/CaretDownSVG'
import { colors } from '../../../../utils/theme/configureTheme'

export type ControlledSelectProps = {
  name: string
  label: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  children?: React.ReactNode
}

export const ControlledSelect = ({ children, label, ...props }: ControlledSelectProps) => {
  return (
    <FormControl bg="brand.transparent" isReadOnly>
      <FormControl.Label
        _text={{
          fontWeight: 600,
          fontSize: 13,
          color: 'brand.softBlack',
        }}
        mt={0}
      >
        {label}
      </FormControl.Label>
      <div style={{ position: 'relative', display: 'inline-block', width: '100%', height: 44 }}>
        <Field
          as="select"
          style={{
            height: 44,
            borderWidth: 2,
            borderRadius: 4,
            borderColor: colors.brand.softBlack,
            bg: colors.brand.transparent,
            paddingLeft: 14,
            fontSize: 16,
            fontWeight: 500,
            color: colors.brand.softBlack,
            fontFamily: 'Matter Regular',
            WebkitAppearance: 'none', // Remove Safari styling
            MozAppearance: 'none', // Remove Firefox styling
            appearance: 'none', // Remove any other styling
            width: '100%', // Ensure it covers the wrapper
            position: 'absolute', // Overlay on top of the custom arrow
            right: 0,
            top: 0,
            zIndex: 1,
          }}
          {...props}
        >
          {children}
        </Field>
        <div
          style={{
            height: 16,
            position: 'absolute',
            right: 14, // Adjust based on the size of the CaretDown icon and padding
            top: '50%',
            transform: 'translateY(-50%)', // Center vertically
            pointerEvents: 'none', // Allow clicks to pass through to the select element
            zIndex: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CaretDownSVG />
        </div>
      </div>
    </FormControl>
  )
}
