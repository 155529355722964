import { useFormikContext } from 'formik'
import { useEffect } from 'react'

import { trackEvent } from '../../../utils/analytics-v2'
import { ModalContent } from '../Common/ModalContent'
import { PhoneFormType, PhoneInput } from './PhoneInput'

export type PhoneModalContentProps = {
  closeModal: () => void
}

export const PhoneModalContent = ({ closeModal }: PhoneModalContentProps) => {
  const { isSubmitting, values, handleSubmit } = useFormikContext<PhoneFormType>()

  useEffect(() => {
    trackEvent('view_authentication', { verification_type: 'phone' })
  }, [])
  return (
    <ModalContent
      headerMain={'Confirm Instructions'}
      headerSecondary={'We need to verify your phone number to add delivery instructions.'}
      submitText={'Get Code'}
      onSubmit={handleSubmit}
      closeModal={closeModal}
      disabled={isSubmitting || !values.acceptedTerms}
      loading={isSubmitting}
    >
      <PhoneInput />
    </ModalContent>
  )
}
