import { Box, Image, Pressable, Text } from 'native-base'
import { isMobile } from 'react-device-detect'

import { trackEvent } from '../utils/analytics-v2'

const Support = ({ showMobile }: { showMobile: boolean }) => {
  const handleLinkClick = () => {
    trackEvent('contact_support', { method: 'sms' })
    window.location.href = `sms:68953`
  }
  return (
    <>
      {isMobile || showMobile ? (
        <Pressable onPress={() => handleLinkClick()} id="contact-support">
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: '20px',
              paddingBottom: '20px',
              borderColor: '#E8E8E8',
              borderWidth: 1,
              paddingLeft: '16px',
              backgroundColor: 'white',
            }}
            flex="1"
            rounded="md"
            textAlign="left"
            height="98px"
            marginX="16px"
          >
            <Box paddingRight="16px">
              <Image
                source={{
                  uri: 'support.svg',
                }}
                resizeMode="contain"
                alt="Support"
                size="sm"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              flex="1"
              paddingRight="20px"
            >
              <Text fontFamily={'bodyBold'} style={{ fontSize: 14, marginBottom: 4 }}>
                Need help with your delivery?
              </Text>
              <Text style={{ fontSize: 14 }}>Text us at 68953 to connect with a support agent.</Text>
            </Box>
            <Box marginRight="26px" alignItems="center" justifyContent="center">
              <img src="/chevron_right.svg" alt="logo" width={10} height={16} />
            </Box>
          </Box>
        </Pressable>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '20px',
            borderColor: '#E8E8E8',
            borderWidth: 1,
            padding: 24,
            backgroundColor: 'white',
          }}
          flex="1"
          rounded="md"
          textAlign="center"
          maxWidth="316px"
        >
          <Image
            source={{
              uri: 'support.svg',
            }}
            resizeMode="contain"
            alt="Support"
            size="lg"
          />
          <Text fontFamily={'bodyBold'} style={{ fontSize: 14, paddingTop: 24, marginBottom: 4, flex: 1 }}>
            Need help with your delivery?
          </Text>
          <Text marginBottom={4} style={{ fontSize: 14 }}>
            Text us at 68953 to connect with a support agent.
          </Text>
          <Pressable id="contact-support" onPress={() => handleLinkClick()}>
            <Box
              paddingX="16px"
              paddingY="6px"
              bg="#ECECE5"
              rounded="lg"
              borderRadius={100}
              _text={{
                color: 'black',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              Text Support
            </Box>
          </Pressable>
        </Box>
      )}
    </>
  )
}

export default Support
