import { DateTime, Interval } from 'luxon'

import packageEvents, { PackageDelayedIssue, errorEvents } from '../enums/package-events'
import { Order, PackageEventOperations } from '../types/graphql'

const isException = (order: Order) => {
  if (order?.packages && order?.packages.length) {
    // contains error event
    const lastEventsFromAllPackages = order.packages.map(p => p.eventLog[0]?.operation)
    if (lastEventsFromAllPackages.some(eventName => errorEvents.includes(eventName))) {
      return true
    }
    // mix of delivered and undelivered packages now that packages can have different scheduled service dates
    const { deliveredPackages, notDeliveredPackages } = order?.calculatedPackagesInformation
    if (deliveredPackages?.total > 0 && notDeliveredPackages?.total > 0) {
      return true
    }
  }
  return false
}

const isDelivered = (order: Order) => {
  if (order && order.packages && order.packages.length) {
    const lastEventsFromAllPackages = order.packages.map(p => p.eventLog[0]?.operation)
    return (
      lastEventsFromAllPackages.includes(packageEvents.DELIVERED as PackageEventOperations) ||
      lastEventsFromAllPackages.includes(packageEvents.PICKED_UP_FROM_CLIENT as PackageEventOperations)
    )
  }
  return false
}

const hasDeliveryWindow = (order: Order) => {
  if (order?.packages && order?.packages.length) {
    const pkg = order?.packages[0]
    if (pkg.deliveryTimeWindow && pkg.deliveryTimeWindow.startsAt) {
      return true
    }
  }
  return false
}

const isPreDeliveryWindowStage = (order: Order) => {
  if (!isException(order) && !isDelivered(order) && !hasDeliveryWindow(order)) {
    return true
  }
  return false
}

const isWithinDeliveryWindowStage = (order: Order) => {
  if (!isException(order) && !isDelivered(order) && hasDeliveryWindow(order)) {
    return true
  }
  return false
}

const isPostDeliveryWindowStage = (order: Order) => {
  if (!isException(order) && isDelivered(order) && !hasDeliveryWindow(order)) {
    return true
  }
  return false
}

export const getDeliveryState = (order: Order) => {
  return {
    isException: isException(order),
    isPreDeliveryWindowStage: isPreDeliveryWindowStage(order),
    isWithinDeliveryWindowStage: isWithinDeliveryWindowStage(order),
    isPostDeliveryWindowStage: isPostDeliveryWindowStage(order),
  }
}

export const getDelayDetailText = (delayType: string) => {
  let detailText = ''
  switch (delayType) {
    case PackageDelayedIssue.accessIssueFrontGate:
      detailText = `Our apologies! It looks like a Veho driver partner attempted delivery, but was unable to access your front gate. Don't worry, we will reattempt delivery within the next 24 hours. To make sure we get it right this time, please add your gate access code into the Veho app or share via text with our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}.`
      break
    case PackageDelayedIssue.accessIssueBuildingOrDoorCode:
      detailText = `Our apologies! It looks like a Veho driver partner attempted delivery, but was unable to access your building. Don't worry, we will reattempt delivery within the next 24 hours. To make sure we get it right this time, please provide your driver with your building door code by adding it into the Veho app or by texting our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}.`
      break
    case PackageDelayedIssue.accessIssueSchoolCampus:
      detailText = `Our apologies! It looks like a Veho driver partner attempted delivery, but was unable to deliver to the correct location on campus. Don't worry, we will reattempt delivery within the next 24 hours. To make sure we get it right this time, please provide your driver with detailed instructions by adding them into the Veho app or by texting our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}.`
      break
    case PackageDelayedIssue.wrongLocation:
      detailText = `Our apologies! It looks like finding your address was a bit tricky for our Veho driver partner. We're set to try again in the next 24 hours. To ensure we get it right, please confirm or update your address in our app or by texting our support team at ${process.env.REACT_APP_SMS_SHORTCODE}. Thanks for your patience.`
      break
    case PackageDelayedIssue.closedLocationStoreFront:
      detailText = `Oh no - looks like we missed you, as the storefront was closed upon our arrival. We'll reattempt delivery in the next 24 hours. Could you share your business hours by texting our team at ${process.env.REACT_APP_SMS_SHORTCODE} to ensure a smooth delivery? Thank you!`
      break
    case PackageDelayedIssue.closedLocationOffice:
      detailText = `Sorry we missed you today; the office was closed when the Veho driver partner tried to deliver. We'll make another delivery attempt within 24 hours. If you'd like to provide Veho with your operating hours, you can text our team at ${process.env.REACT_APP_SMS_SHORTCODE}.`
      break
    case PackageDelayedIssue.closedLocationLeasingOffice:
      detailText = `Our apologies - we couldn't complete the delivery because the office was closed. We'll try again within the next 24 hours. Please share the hours of operations with our team by adding them to the app, or texting our support directly at ${process.env.REACT_APP_SMS_SHORTCODE}. Thank you!`
      break
    case PackageDelayedIssue.closedLocationMailroom:
      detailText = `Our apologies - we couldn't complete the delivery because the mailroom was closed. We'll try again within the next 24 hours. Please share the hours of operations with our team by adding them to the app, or texting our support directly at ${process.env.REACT_APP_SMS_SHORTCODE}. Thank you!`
      break
    case PackageDelayedIssue.customerRefused:
      detailText = `Your package is running a bit late, and we truly apologize for any inconvenience this may cause. If you need further assistance, please reach out to the Veho Support team at ${process.env.REACT_APP_SMS_SHORTCODE}.`
      break
    case PackageDelayedIssue.damagedPackage:
      detailText = `Our apologies - your package is delayed. An update will be available within 24 hours. For immediate assistance, please contact our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}. Thank you for your understanding.`
      break
    case PackageDelayedIssue.unsafeConditionsPetOnSite:
      detailText = `We're sorry for the inconvenience, but we encountered a pet on the premises and need to prioritize our Veho driver partner's safety. We plan to reattempt the delivery within the next 24 hours. Could you kindly secure any pets or provide specific instructions through our app or by texting ${process.env.REACT_APP_SMS_SHORTCODE}? We truly appreciate your understanding and cooperation. Thank you.`
      break
    case PackageDelayedIssue.unsafeConditionsWeatherRelated:
      detailText =
        "Weather conditions made it unsafe to deliver your package today. We're sincerely sorry for this delay and a Veho driver partner will reattempt delivery in the next 24 hours. Thank you for your understanding."
      break
    case PackageDelayedIssue.unsafeConditionsDarkUnlitArea:
      detailText = `Your package delivery is delayed, and we sincerely apologize for any inconvenience. An update will be provided within 24 hours. For immediate assistance, please contact our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}. Thank you for your understanding.`
      break
    case PackageDelayedIssue.other:
      detailText = `Your package delivery is delayed, and we sincerely apologize for any inconvenience. An update will be provided within 24 hours. For immediate assistance, please contact our Support team at ${process.env.REACT_APP_SMS_SHORTCODE}. Thank you for your understanding.`
      break
    case PackageDelayedIssue.packageDelayed:
      detailText =
        "We're sorry for the delay. We're working to get your package back on track. Please return in 2 days if you still don't have it, and we'll help you out!"
      break
    default:
      break
  }

  return detailText
}

export const potentiallyOverNinetyDaysAgo = (deliveryDate: string): boolean => {
  const now = DateTime.now()
  const ninetyDaysAgo = DateTime.fromISO(deliveryDate)
  const interval = Interval.fromDateTimes(ninetyDaysAgo, now).length('days')
  return interval >= 90 ? true : false
}
