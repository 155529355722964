import { createContext, useMemo, useState } from 'react'

import { Order, OrderServiceType, OrderStatus } from '../types/graphql'

interface AppContextValue {
  order?: Order
  setOrder: (order: Order) => void
}

const AppDefaultState: AppContextValue = {
  order: {
    orderId: '',
    packages: [],
    reroutableZipCodes: [],
    serviceType: {} as OrderServiceType,
    address: {},
    clientDisplayName: '',
    isEditable: false,
    isPastConsumerRerouteCutoff: false,
    isPastRerouteCutoff: false,
    clientName: '',
    calculatedOrderInformation: { orderDeliveryDates: [], orderStatus: {} as OrderStatus },
    calculatedPackagesInformation: {
      deliveredPackages: {
        groupedByDeliveredAtTime: [],
        total: 0,
      },
      notDeliveredPackages: {
        groupedByDeliveryDate: [],
        groupedByDeliveryWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingDeliveryDate: [],
        total: 0,
      },
      totalPackages: 0,
      notPickedUpPackages: {
        __typename: 'NotPickedUpPackages',
        groupedByEveningPackages: [],
        groupedByMissingPickUpDate: [],
        groupedByPickUpDate: [],
        groupedByPickUpWindow: [],
        total: 0,
      },
      pickedUpPackages: {
        __typename: 'PickedUpPackages',
        groupedByPickedUpAtTime: [],
        total: 0,
      },
    },
    isOrderReroutable: false,
    isOrderReroutableByConsumer: false,
  },
  setOrder: () => {},
}

export const AppContext = createContext<AppContextValue>(AppDefaultState)

function AppContextProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [order, setOrder] = useState({
    orderId: '',
    packages: [],
    reroutableZipCodes: [],
    serviceType: {} as OrderServiceType,
    address: {},
    clientDisplayName: '',
    isEditable: false,
    isPastConsumerRerouteCutoff: false,
    isPastRerouteCutoff: false,
    clientName: '',
    calculatedOrderInformation: { orderDeliveryDates: [], orderStatus: {} as OrderStatus },
    calculatedPackagesInformation: {
      deliveredPackages: {
        groupedByDeliveredAtTime: [],
        total: 0,
      },
      notDeliveredPackages: {
        groupedByDeliveryDate: [],
        groupedByDeliveryWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingDeliveryDate: [],
        total: 0,
      },
      pickedUpPackages: {
        groupedByPickedUpAtTime: [],
        total: 0,
      },
      notPickedUpPackages: {
        groupedByPickUpDate: [],
        groupedByPickUpWindow: [],
        groupedByEveningPackages: [],
        groupedByMissingPickUpDate: [],
        total: 0,
      },
      totalPackages: 0,
    },
    isOrderReroutable: false,
    isOrderReroutableByConsumer: false,
  } as Order)
  const state = useMemo(() => {
    return { order, setOrder }
  }, [order, setOrder])
  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
}

export default AppContextProvider
