import { Button, Heading, Modal, Text, VStack } from 'native-base'

import WarningTriangleSVG from '../../../assets/WarningTriangleSVG'
import { AddInstructionsLaterButton } from '../Common/AddInstructionsLaterButton'

export type PhoneNumberMismatchModalContentProps = {
  onSubmit: () => void
  closeModal: () => void
}

export const PhoneNumberMismatchModalContent = ({ onSubmit, closeModal }: PhoneNumberMismatchModalContentProps) => {
  return (
    <Modal.Content
      justifyContent={'center'}
      alignItems={'stretch'}
      overflowY={'auto'}
      bg={'brand.warmWhite'}
      width={350}
      maxWidth={'90svw'}
      height={550}
      maxHeight={'95svh'}
    >
      <VStack space={4} p={3} my={4}>
        <Heading textAlign={'center'} size={'md'} fontWeight={500}>
          Issue Submitting Instructions
        </Heading>
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <WarningTriangleSVG />
          <Heading textAlign={'center'} size={'sm'} fontWeight={500}>
            Your phone number doesn't match
          </Heading>
          <Text textAlign={'center'} fontSize={14} color={'brand.softBlack'}>
            The phone number you entered does not match the phone number on file for this order.
          </Text>
        </VStack>
        <VStack space={1} alignItems={'center'} w={'100%'}>
          <Button onPress={onSubmit} _focusVisible={{ outlineStyle: 'none' }}>
            Enter New Phone Number
          </Button>
          <AddInstructionsLaterButton closeModal={closeModal} />
        </VStack>
      </VStack>
    </Modal.Content>
  )
}
