// import { getAnalytics, logEvent } from 'firebase/analytics'
import { Box, Button, FormControl, Heading, Image, Input, Link, Text, Tooltip } from 'native-base'
import { useCallback, useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'

import { useLazyQuery } from '@apollo/client'

import InfoIcon from '../assets/info.svg'
import { AppContext } from '../context/AppContextProvider'
import { GET_ORDER_BY_TRACKING_ID } from '../graphql/getOrderByTrackingId'
import { Order } from '../types/graphql'
import { trackEvent } from '../utils/analytics-v2'
import { colors } from '../utils/theme/configureTheme'
import Loader from './Loader'

const Home = ({ showMobile }: { showMobile: boolean }) => {
  const [trackingId, setTrackingId] = useState('')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams(window.location.search)

  const [formData, setData] = useState({ trackingId: '' })
  const [errors, setErrors] = useState({})

  const { setOrder } = useContext(AppContext)

  const [getOrderByTrackingIdCWA, { loading, error, data }] = useLazyQuery<{
    order: Order
  }>(GET_ORDER_BY_TRACKING_ID, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { trackingId },
    onCompleted: () => trackEvent('track_package', { tracking_code: trackingId }),
    onError: () => trackEvent('track_package_error', { error_reason: 'unknown', tracking_code: trackingId }),
  })

  useEffect(() => {
    navigate('/#')
    new SmartBanner({
      // eslint-disable-line no-new
      daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
      daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
      title: 'Veho Mobile',
      author: 'Veho Technologies',
      button: 'VIEW',
      store: {
        ios: 'On the App Store',
        android: 'In Google Play',
      },
      price: {
        ios: 'FREE',
        android: 'FREE',
      },
    })
    // eslint-disable-next-line
  }, [])

  const formatTrackingId = useCallback(
    (textValue: string) => {
      setErrors({})
      //Tracking code alone needs to be uppercased
      setData({
        ...formData,
        trackingId:
          textValue.length === 6 || textValue.length === 14 ? textValue.toUpperCase().trim() : textValue.trim(),
      })
    },
    [formData]
  )

  const getOrderAndNavigate = useCallback(
    async (trackingId: string) => {
      formatTrackingId(trackingId)
      await getOrderByTrackingIdCWA()
      // url prefix to the trackingId varies depending on length since we allow barcodes to be used
      const urlParam = trackingId?.length === 6 || trackingId?.length === 14 ? 'trackingId' : 'barcode'
      navigate(`${urlParam}` + trackingId)
      // replace the url path to remove the unnecessary query params
      const newUrl = [window.location.pathname, trackingId].filter(Boolean).join(`#/${urlParam}/`)
      window.history.replaceState(null, '', newUrl)
    },
    [navigate, formatTrackingId, getOrderByTrackingIdCWA]
  )

  useEffect(() => {
    const trackingId = searchParams.get('trackingId')

    if (trackingId) {
      getOrderAndNavigate(trackingId)
    }
  }, [searchParams, getOrderAndNavigate])

  useEffect(() => {
    if (error || data?.order === null) {
      setErrors({ ...errors, trackingId: 'ERROR' })
    }
    if (data?.order) {
      setOrder(data?.order)
    }

    if (data && data?.order !== null) {
      if (trackingId?.length === 6 || trackingId?.length === 14) {
        navigate('/trackingId/' + trackingId)
      } else {
        navigate('/barcode/' + trackingId)
      }
    }
    // eslint-disable-next-line
  }, [data, trackingId, setOrder, navigate])

  const onSearchBtnClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (_: any, enterPressed?: boolean) => {
      if (
        formData?.trackingId?.length === 0 ||
        (formData?.trackingId?.length !== 6 && formData?.trackingId?.length < 10)
      ) {
        setErrors({ ...errors, isInvalid: true })
        trackEvent('track_package_error', { error_reason: 'validation', tracking_code: formData?.trackingId })
      } else {
        setTrackingId(formData.trackingId)
        await getOrderByTrackingIdCWA()
      }
    },
    [formData, getOrderByTrackingIdCWA, errors]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onEnterKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.which === 13) {
      onSearchBtnClick(event, true)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="stretch"
      maxWidth={'695px'}
      width={isMobile || showMobile ? '85%' : '50%'}
      paddingTop={5}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box paddingBottom={isMobile ? 5 : 1} display="flex" textAlign={isMobile ? 'center' : null}>
            <Heading fontFamily={'headingRebrand'} fontWeight={400} fontSize={'3xl'}>
              Track my package
            </Heading>
          </Box>
          <Box>
            <FormControl isInvalid={'trackingId' in errors || 'isInvalid' in errors} backgroundColor={'#F5F5ED'}>
              <FormControl.Label display="flex" alignItems="center">
                Tracking Code
                <Box marginLeft={1}>
                  <Tooltip
                    label="Enter your tracking code or barcode to track a package"
                    placement="right"
                    width="221px"
                  >
                    <img src={InfoIcon} alt="information" width="14px" height="14px" />
                  </Tooltip>
                </Box>
              </FormControl.Label>
              <Input
                placeholder="Enter tracking code"
                autoCapitalize={'characters'}
                onChangeText={(value: string) => formatTrackingId(value.trim())}
                onKeyPress={onEnterKeyPress}
                backgroundColor={'white'}
                borderColor={colors.brand.softBlack}
                borderWidth={2}
                fontSize={16}
              />
              {'isInvalid' in errors ? (
                <FormControl.ErrorMessage>Please enter a valid tracking code</FormControl.ErrorMessage>
              ) : (
                'trackingId' in errors && (
                  <FormControl.ErrorMessage>
                    We can't find a package associated with that number. Please double check your tracking code or
                    barcode. If you need help, text <Link href="sms:68953">68953</Link> to contact support.
                  </FormControl.ErrorMessage>
                )
              )}
            </FormControl>
          </Box>

          <Box>
            <Button
              id="trackingBtn"
              onPress={onSearchBtnClick}
              variant="solid"
              borderRadius={100}
              mt={4}
              mb={2}
              bgColor={colors.primary[300]}
            >
              <Box display="flex" flexDir={'row'} alignItems={'center'}>
                <Text color="white" fontWeight={500}>
                  Track Package
                </Text>
                <Image
                  source={{ uri: 'ArrowRight.svg' }}
                  resizeMode="contain"
                  width={6}
                  height={6}
                  ml={1}
                  p={0}
                  alt="Arrow Right"
                />
              </Box>
            </Button>
            <Box alignItems={'center'}>
              <Link
                href="https://www.shipveho.com/customers-faq"
                color={colors.primary[300]}
                fontSize={14}
                mt={2}
                justifyContent={'center'}
                display={'inline-block'}
              >
                Need help?
              </Link>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Home
