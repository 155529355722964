import { useField, useFormikContext } from 'formik'
import { FormControl, HStack, IInputProps, Text, VStack } from 'native-base'
import React, { memo } from 'react'

import { colors } from '../../../../utils/theme/configureTheme'
import { FormikInput } from './FormikInput'
import { FormikTextArea } from './FormikTextArea'

const ControlledTextInput = (props: IInputProps) => {
  const {
    autoComplete,
    autoFocus,
    hint,
    keyboardType,
    label,
    leftIcon,
    leftHintIcon,
    name,
    inputType,
    optional,
    charLimit,
    charLimitCounter,
    errorMessageExternal,
  } = props
  const [, meta] = useField<string>(name)
  const { isSubmitting } = useFormikContext()

  return (
    <FormControl
      isInvalid={meta.error?.length || errorMessageExternal ? true : false}
      key={`${name}-form-control`}
      testID={`${name}-form-control`}
      bg={!props?.bg ? 'transparent' : props.bg}
      style={props.style}
    >
      <VStack alignItems={'stretch'} width={'100%'}>
        <HStack justifyContent={'space-between'} backgroundColor={colors.brand.warmWhite}>
          <HStack>
            {label && (
              <FormControl.Label
                key={`${name}-label`}
                testID={`${name}-label`}
                mt={0}
                _text={{
                  fontWeight: 600,
                  fontSize: 13,
                  lineHeight: 19.5,
                  color: colors.brand.softBlack,
                }}
              >
                {label}
              </FormControl.Label>
            )}
            {optional && (
              <FormControl.Label
                key={`${name}-optional-label`}
                testID={`${name}-optional-label`}
                ml={3}
                mt={0}
                _text={{
                  fontWeight: 700,
                  fontSize: 11,
                  color: props?.isDisabled ? colors.gray[200] : colors.brand.darkGrey,
                  alignSelf: 'center',
                  textTransform: 'uppercase',
                }}
                isDisabled={props?.isDisabled}
              >
                Optional
              </FormControl.Label>
            )}
          </HStack>
          <HStack>
            {charLimitCounter && (
              <FormControl.HelperText
                key={`${name}-char-limit-label`}
                testID={`${name}-char-limit-label`}
                _text={{ fontWeight: 600, fontSize: 12, color: colors.brand.darkGrey }}
              >
                {`${meta.value?.length ?? 0}/${charLimit}`}
              </FormControl.HelperText>
            )}
          </HStack>
        </HStack>
        {inputType === 'textarea' ? (
          <FormikTextArea
            name={name}
            bg={colors.brand.white}
            _focus={{
              bg: colors.brand.white,
              borderColor: colors.brand.softBlack,
              placeholderTextColor: colors.gray[400],
            }}
            _hover={{
              placeholderTextColor: colors.gray[400],
            }}
            size="md"
            isDisabled={props?.isDisabled || isSubmitting}
            alignItems="flex-start"
            keyboardType={keyboardType}
            autoCompleteType={undefined}
            borderColor={colors.brand.softBlack}
            borderWidth={2}
            h={100}
            key={`${name}-textarea-input`}
            testID={`${name}-textarea-input`}
            {...props}
            maxLength={charLimit}
            invalidOutlineColor={colors.error[300]}
          />
        ) : (
          <FormikInput
            name={name}
            key={`${name}-text-input`}
            testID={`${name}-text-input`}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            bg={colors.brand.white}
            _focus={{
              bg: colors.brand.white,
              borderColor: colors.brand.softBlack,
              placeholderTextColor: colors.gray[400],
            }}
            _hover={{
              placeholderTextColor: colors.gray[400],
            }}
            size="md"
            isDisabled={props?.isDisabled || isSubmitting}
            InputLeftElement={leftIcon}
            keyboardType={keyboardType}
            borderColor={colors.brand.softBlack}
            borderWidth={2}
            maxLength={charLimit}
            {...props}
          />
        )}
        {meta.error && !errorMessageExternal ? (
          <FormControl.ErrorMessage fontSize={12} testID={`${name}-error`}>
            {meta.error}
          </FormControl.ErrorMessage>
        ) : (
          hint && (
            <FormControl.HelperText
              mt={1}
              fontSize={13}
              color={'brand.darkGrey'}
              key={`${name}-hint`}
              testID={`${name}-hint`}
            >
              <HStack alignItems={'center'} backgroundColor={colors.brand.warmWhite} space={5}>
                {leftHintIcon}
                <Text fontSize={'inherit'} color={'inherit'} fontWeight={'inherit'} lineHeight={'inherit'}>
                  {hint}
                </Text>
              </HStack>
            </FormControl.HelperText>
          )
        )}
      </VStack>
    </FormControl>
  )
}

ControlledTextInput.defaultProps = {
  required: false,
  inputType: 'text',
}

export default memo(ControlledTextInput)
