import { useFormikContext } from 'formik'
import { Checkbox, FormControl, Link, Text, VStack } from 'native-base'

import { trackEvent } from '../../../utils/analytics-v2'
import { PhoneFormType } from './PhoneInput'

export const TermsAndConditions = () => {
  const { setFieldValue, values, errors } = useFormikContext<PhoneFormType>()

  return (
    <VStack alignItems={'center'}>
      <FormControl bg={'brand.transparent'} isRequired isInvalid={!!errors.acceptedTerms}>
        <Checkbox
          isChecked={values.acceptedTerms}
          onChange={isSelected => setFieldValue('acceptedTerms', isSelected)}
          value={'acceptedTerms'}
          accessibilityLabel={'accept terms'}
          borderRadius={4}
          borderWidth={2}
          borderColor={'brand.softBlack'}
          backgroundColor={'brand.transparent'}
          _icon={{
            size: 3,
            color: 'brand.softBlack',
          }}
          _checked={{
            borderWidth: 2,
            borderColor: 'brand.softBlack',
            backgroundColor: 'brand.transparent',
          }}
          _stack={{
            alignItems: 'center',
          }}
        >
          <Text fontSize={14} fontWeight={400} lineHeight={17.5}>
            By adding instructions, I acknowledge that I have read the{' '}
            <Link
              href="https://www.shipveho.com/privacy"
              isExternal
              onPress={() => trackEvent('navigate_to_privacy_page', { privacy_type: 'instructions' })}
            >
              Privacy Policy
            </Link>
            {' and agreed to the '}
            <Link
              href="https://www.shipveho.com/consumerapp/terms"
              isExternal
              onPress={() => trackEvent('navigate_to_terms_page', { terms_type: 'instructions' })}
            >
              Terms of Service
            </Link>
            .
          </Text>
        </Checkbox>
        <FormControl.ErrorMessage fontSize={12}>{errors.acceptedTerms}</FormControl.ErrorMessage>
      </FormControl>
    </VStack>
  )
}
