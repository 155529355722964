import Analytics from 'analytics'

import googleAnalytics from '@analytics/google-analytics'

type BaseProps = {
  // Not implemented yet
  // client_type: string
  // client_name: string
  // client_id: string
}

interface DownloadAppProps {
  element: 'button'
  platform: 'ios' | 'android'
}
interface TrackPackageProps {
  tracking_code: string
}

interface TrackPackageErrorProps {
  tracking_code: string
  error_reason: 'unknown' | 'validation'
}
interface ContactSupportProps {
  method: 'sms'
}
interface ProvideFeedbackProps {
  method: 'google_form'
}
interface NavigateToMainSite {
  element: 'logo' | 'footer_link'
}
interface ReturnToTrackingPage {}
interface ViewFaq {}

interface AddInstructions extends BaseProps {
  // temp disabled, will be addressed in follow up
  // property_type: string
  // package_placement_preference: string
  // beware_of_dog: boolean
  // gated_community: boolean
  // security_code: boolean
}

interface AddInstructionsSuccess extends BaseProps {
  // temp disabled, will be addressed in follow up
  // property_type: string
  // package_placement_preference: string
  // beware_of_dog: boolean
  // gated_community: boolean
  // security_code: boolean
}

interface AddInstructionsError extends BaseProps {
  error_reason: 'mismatched_phone' | 'unknown'
  // temp disabled, will be addressed in follow up
  // property_type: string
  // package_placement_preference: string
  // beware_of_dog: boolean
  // gated_community: boolean
  // security_code: boolean
}
interface GetAuthenticationCode extends BaseProps {
  auth_flow: 'instructions'
}

interface GetAuthenticationCodeSuccess extends BaseProps {
  auth_flow: 'instructions'
}

interface GetAuthenticationCodeError extends BaseProps {
  auth_flow: 'instructions'
  error_reason: 'verification_failed' | 'unknown'
}
interface SubmitVerificationCode extends BaseProps {
  auth_flow: 'instructions'
}
interface SubmitVerificationCodeSuccess extends BaseProps {
  auth_flow: 'instructions'
}
interface SubmitVerificationCodeError extends BaseProps {
  auth_flow: 'instructions'
  error_reason: 'code_mismatch' | 'code_expired' | 'too_many_attempts' | 'unknown'
}
interface ViewAuthentication extends BaseProps {}
interface ViewInstructions extends BaseProps {
  trigger: 'manual' | 'auto'
}
interface ViewVerification extends BaseProps {
  verification_type: 'phone'
}

interface NavigateToPrivacyPage extends BaseProps {
  privacy_type: 'instructions'
}
interface NavigateToTermsPage extends BaseProps {
  terms_type: 'instructions'
}
interface DismissInstructions extends BaseProps {}

export interface EventMap {
  download_app: DownloadAppProps
  track_package: TrackPackageProps
  track_package_error: TrackPackageErrorProps
  contact_support: ContactSupportProps
  provide_feedback: ProvideFeedbackProps
  navigate_to_main_site: NavigateToMainSite
  return_to_tracking_page: ReturnToTrackingPage
  view_faq: ViewFaq
  add_instructions: AddInstructions
  add_instructions_success: AddInstructionsSuccess
  add_instructions_error: AddInstructionsError
  get_authentication_code: GetAuthenticationCode
  get_authentication_code_error: GetAuthenticationCodeError
  get_authentication_code_success: GetAuthenticationCodeSuccess
  submit_verification_code: SubmitVerificationCode
  submit_verification_code_success: SubmitVerificationCodeSuccess
  submit_verification_code_error: SubmitVerificationCodeError
  view_authentication: ViewAuthentication
  view_instructions: ViewInstructions
  view_verification: ViewVerification
  navigate_to_privacy_page: NavigateToPrivacyPage
  navigate_to_terms_page: NavigateToTermsPage
  dismiss_instructions: DismissInstructions
}

export const analytics = Analytics({
  app: 'consumer-web-app',
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID as string],
    }),
  ],
})

export function trackEvent<K extends keyof EventMap>(eventName: K, props: EventMap[K]): void {
  analytics.track(eventName, props)
}
