import { useFormikContext } from 'formik'
import { Pressable, Text, VStack, View } from 'native-base'
import { useState } from 'react'
import { LayoutAnimation } from 'react-native'

import { OrderServiceType } from '../../../types/graphql'
import { colors } from '../../../utils/theme/configureTheme'
import ControlledTextInput from './Input/ControlledTextInput'
import { InstructionsFormType } from './InstructionsForm'
import { PreferredLocationInput } from './PreferredLocationInput'
import { PREFERRED_LOCATIONS } from './preferredLocations'

export interface OtherInstructionsInputProps {
  serviceType: OrderServiceType
}

export const OtherInstructionsInput = ({ serviceType }: OtherInstructionsInputProps) => {
  const { values, setFieldValue } = useFormikContext<InstructionsFormType>()
  const [showOtherInstructions, setShowOtherInstructions] = useState(
    serviceType === OrderServiceType.Pickup ? !!values.pickupInstructions : !!values.instructions
  )

  const onPress = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setShowOtherInstructions(true)

    // Set the instructions and preferred location to empty when user clicks on the link to specify other details
    setFieldValue(serviceType === OrderServiceType.Pickup ? 'pickupInstructions' : 'instructions', '')
    setFieldValue('preferredLocation', PREFERRED_LOCATIONS.NoPreference)
  }

  return !showOtherInstructions ? (
    <View>
      <Pressable onPress={onPress}>
        <Text
          style={{
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: 17.5,
            fontWeight: '500',
            textDecorationLine: 'underline',
            color: colors.brand.darkVermillion,
            paddingVertical: 0,
          }}
        >
          Need to specify other details?
        </Text>
      </Pressable>
    </View>
  ) : (
    <VStack space={2} alignItems={'stretch'} width={'100%'}>
      <PreferredLocationInput serviceType={serviceType} />
      <ControlledTextInput
        label={'Other Details'}
        placeholder={
          serviceType === OrderServiceType.Pickup
            ? 'e.g. Next to the plant stand by the front door'
            : 'e.g. leave it next to welcome mat'
        }
        name={serviceType === OrderServiceType.Pickup ? 'pickupInstructions' : 'instructions'}
        inputType="textarea"
        optional={true}
        charLimit={250}
        charLimitCounter={false}
        handleScrollYOffset={20}
        useDebounce={true}
        debounceWait={250}
      />
    </VStack>
  )
}
