import { Form, useFormikContext } from 'formik'
import { Heading, VStack } from 'native-base'
import * as yup from 'yup'

import PhoneFormPhoneNumberSVG from '../../../assets/PhoneFormPhoneNumberSVG'
import MaskedTextInput from '../Form/Input/MaskedTextInput'
import { TermsAndConditions } from './TermsAndConditions'

const typeErrorString = 'This field must be a string.'
const typeErrorBoolean = 'This field must be a boolean.'
const phoneRequired = 'Phone number is required.'
const phoneValid = 'Must be a valid phone number.'
const termsRequired = 'Must accept Terms and Conditions.'

const isPhoneNumberValid = (phone: string | undefined) => {
  if (phone) {
    const strippedValue = phone?.replace(/\D+/g, '')
    if (
      strippedValue &&
      /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/.test(
        strippedValue
      ) === false
    ) {
      return false
    }

    return true
  }

  return false
}

export const phoneFormSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .typeError(typeErrorString)
    .required(phoneRequired)
    .test('is-phone-number', phoneValid, value => isPhoneNumberValid(value)),
  acceptedTerms: yup.boolean().typeError(typeErrorBoolean).isTrue(termsRequired),
})

export type PhoneFormType = {
  phoneNumber: string
  acceptedTerms: boolean
}

export const PhoneInput = () => {
  const { handleChange, handleBlur, values, errors } = useFormikContext<PhoneFormType>()

  return (
    <Form style={{ alignItems: 'stretch', width: '100%' }}>
      <VStack alignItems={'stretch'} space={4}>
        <VStack alignItems={'center'}>
          <PhoneFormPhoneNumberSVG />
        </VStack>
        <Heading size={'sm'} textAlign={'center'}>
          Enter your phone number and we'll text you a verification code.
        </Heading>
        <MaskedTextInput
          autoComplete="tel"
          textContentType="telephoneNumber"
          borderWidth={2}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.phoneNumber}
          keyboardType="phone-pad"
          mask="+1 (999) 999-9999"
          name="phoneNumber"
          placeholder="(___) ___-____"
          value={values.phoneNumber}
          borderColor={'brand.softBlack'}
        />
        <TermsAndConditions />
      </VStack>
    </Form>
  )
}
