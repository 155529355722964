import { Box, Button, Text, VStack } from 'native-base'
import { isMobile } from 'react-device-detect'

import { useScreenSize } from '../utils/displayUtils'

const FAQ = () => {
  const screenSize = useScreenSize()
  const isMobileScreen = isMobile || screenSize.width < 1100

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="stretch"
        flexDirection={'column'}
        px={3}
        py={8}
        width="100%"
        backgroundColor="#ECECE5"
        my={20}
      >
        <VStack alignItems={'center'} w={'100%'} space={4}>
          <Text
            fontFamily="Burgess"
            fontWeight={400}
            fontSize={isMobileScreen ? '32px' : '40px'}
            letterSpacing={'-.8px'}
            lineHeight={'1em'}
            textAlign={'center'}
          >
            Questions about your order?
          </Text>
          <Button size="lg" onPress={() => window.open('https://www.shipveho.com/customers-faq')}>
            FAQs
          </Button>
        </VStack>
      </Box>
    </>
  )
}

export default FAQ
